export const EMPLOYEE_TYPE = {
  MANAGER: 'manager',
  STAFF: 'staff'
};

export const COMMUNICATION_PREF_TYPE = {
  ONLINE: 'online',
  FACE_TO_FACE: 'betreuungVorOrt'
};

export const COMMUNICATION_PREF_ICON = {
  ONLINE: 'wifi',
  FACE_TO_FACE: 'home'
};

export const PLAN_TIERS = {
  PREMIUM: 'premium',
  PREMIUM_PLUS: 'premiumPlus'
};