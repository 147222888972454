/*
*  put here some vales which is not changed with the time like options in select for homepage
* */
export const filterNames = {
  KANTONES: 'kantones',
  CITY: 'cities',
  ZIP: 'zip',
  SOFTWARE: 'software',
  COMMUNICATION_PREFERENCES: 'communicationPreferences',
  BASIS: 'accountingBasis',
  SERVICES: 'specialisation',
  INDUSTRIES: 'industries',
  SPECIAL_OFFER: 'specialOffer',
  LANGUAGES: 'languages',
  MEMBER: 'members',
  HOURLY_RATE: 'rate',
  TAX_REPRESENTATION: 'isTaxRepresentation',
  LOCATIONS: 'locations',
  RATE: 'rate',
  SEARCH: 'search',
};

export const SOCIAL_MEDIA = {
  FACEBOOK: 'facebook',
  LINKED_IN: 'linkedIn',
  SKYPE: 'skype',
  WHATS_APP: 'whatsApp',
  TWITTER: 'twitter',
  GOOGLE_MY_BUSINESS: 'googleMyBusiness',
  YOUTUBE: 'youtube'
};