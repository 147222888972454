import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { DisplayFlexS } from '../../../../utils/stylesHelpers';
import { getThemeColor, getThemeSize, getThemeFont } from '../../../../utils/theme';
import { PrimaryButtonWrapS, PrimaryButtonS } from '../index';

const ResetBtnS = styled(PrimaryButtonWrapS)`
  width: auto;
  display: inline-block;
  padding: 2px 0;
  margin-right: 77px;
  border-radius: 0;
  text-transform: capitalize;
  outline: none;
  font-family: ${getThemeFont(['regular'])};
  font-size: ${getThemeSize(['smallText'])};
  color: ${getThemeColor(['violet'])};
  border-bottom: 1px solid ${getThemeColor(['violet'])};
  background-color: transparent;

  &:hover {
    box-shadow: none;
    border-color: transparent;
  }
`;

export const HistogramButtons = ({ onClear, label, filterName, inputData, setFilter }) => {
  const handleSave = () => {
    setFilter({
      filterName: label,
      value: {
        [filterName]: inputData,
      },
    });
  };

  return (
    <DisplayFlexS
      justify="space-between"
      aItems="center"
      justifyScreenMedium="center"
      alignItemsScreenMedium="center"
      style={{ marginTop: '10px' }}
      className="histogram-buttons"
    >
      <ResetBtnS
        onClick={() => {
          onClear();
        }}
      >
        {i18n.t(['companies.filter.histogramResetBtn'])}
      </ResetBtnS>
      <PrimaryButtonS bgColor="violet" padding="7px 18px" transform="capitalize" fz="normalText" onClick={handleSave}>
        {i18n.t(['companies.filter.histogramSaveBtn'])}
      </PrimaryButtonS>
    </DisplayFlexS>
  );
};

HistogramButtons.propTypes = {
  onClear: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  inputData: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
};
