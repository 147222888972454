import { t } from 'i18next';

import { PLAN_TIERS } from '../../app/constants/company';

export const getPlanTierLabel = (planTier) => {
  switch (planTier) {
    case PLAN_TIERS.PREMIUM:
      return t('planTier.premium');
    case PLAN_TIERS.PREMIUM_PLUS:
      return t('planTier.premiumPlus');
    default:
      return t('planTier.basic');
  }
};
