import React from 'react';
import { useDispatch } from 'react-redux';

import { updatePlanTier } from '../../../../modules/company/companyActions';
import { getPlanTierLabel } from '../../../../utils/helpers/getPlanTier';
import { PLAN_TIERS } from '../../../constants/company';
import { Text } from '../../common';
import { PlanTierButtonsS, CheckBoxS } from '../../common/table/TableS';

const PLAN_TIERS_VALUES = ['Basic', PLAN_TIERS.PREMIUM, PLAN_TIERS.PREMIUM_PLUS];

const PlanTierButtons = ({ planTier, companyId, page, itemsPerPage, sort, companiesStateType }) => {
  const dispatch = useDispatch();
  const currentPlanTier = planTier ? planTier : PLAN_TIERS_VALUES[0];

  const handleChangePlanTier = (updatedPlanTier) => {
    const updatedPlanTierValue = updatedPlanTier !== PLAN_TIERS_VALUES[0] ? updatedPlanTier : null;

    dispatch(updatePlanTier(updatedPlanTierValue, companyId, (page - 1), itemsPerPage, sort, companiesStateType));
  };

  return (
    <PlanTierButtonsS>
      {PLAN_TIERS_VALUES.map((tier) => (
          <label key={tier}>
            <Text weight={currentPlanTier === tier ? 'bold' : ''}>
              {getPlanTierLabel(tier)}
            </Text>
            <CheckBoxS
              checked={currentPlanTier === tier}
              onChange={() => handleChangePlanTier(tier)}
            />
          </label>
      ))}
    </PlanTierButtonsS>
  );
};

export default PlanTierButtons;